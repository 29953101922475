import { Injectable, signal } from '@angular/core';
import { ChatMessage } from '../models/chat-message';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatWsService {
  ws: WebSocket;
  chatStream = new BehaviorSubject<ChatMessage | null>(null);
  chatStream$ = this.chatStream.asObservable();
  wsConnected = signal(false);

  constructor() {
    this.connect();
  }

  connect() {
    const url = environment.chatWsUrl + localStorage.getItem('token');
    this.ws = new WebSocket(url);

    this.ws.onopen = () => this.wsConnected.set(true);

    this.ws.onmessage = (event) =>
      this.chatStream.next(JSON.parse(event.data) as ChatMessage);

    this.ws.onerror = (event) => {
      console.error('Socket error:', event);
    };
  }

  sendMessage(message: ChatMessage): void {
    this.ws.send(JSON.stringify(message));
  }

  close() {
    this.ws.close();
  }
}
