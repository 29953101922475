import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { lastValueFrom, map } from 'rxjs';
import { BackendResponse } from '@fc-core/models/response.model';
import { ChatMessage } from './chat-message';
import { Channel } from './channel';

export enum ChannelType {
  GROUP = 'group',
  DIRECT = 'direct',
}

export interface ChannelPayload {
  name?: string;
  description?: string;
  type?: ChannelType;
  members: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  private url = environment.apiUrl + 'api/chat/channel/';
  private http = inject(HttpClient);

  getChannels(): Promise<Channel[]> {
    return lastValueFrom(
      this.http
        .get<BackendResponse<Channel[]>>(this.url)
        .pipe(map((res) => res.results)),
    );
  }

  updateChannel(channel: Channel): Promise<Channel> {
    return lastValueFrom(
      this.http.put<Channel>(`${this.url}${channel.id}/`, channel),
    );
  }

  createChannel(channelPayload: ChannelPayload): Promise<Channel> {
    return lastValueFrom(this.http.post<Channel>(this.url, channelPayload));
  }

  getChannelMessages(channelId: string): Promise<ChatMessage[]> {
    return lastValueFrom(
      this.http
        .get<
          BackendResponse<ChatMessage[]>
        >(`${this.url}${channelId}/messages/`)
        .pipe(map((res) => res.results)),
    );
  }

  deleteChannel(channelId: string): Promise<void> {
    return lastValueFrom(this.http.delete<void>(`${this.url}${channelId}/`));
  }

  // MESSAGES
  patchMessage(message: Partial<ChatMessage>): Promise<ChatMessage> {
    return lastValueFrom(
      this.http.patch<ChatMessage>(
        `${environment.apiUrl}api/chat/messages/${message.id}/`,
        message,
      ),
    );
  }

  deleteMessage(messageId: string): Promise<void> {
    return lastValueFrom(
      this.http.delete<void>(
        `${environment.apiUrl}api/chat/messages/${messageId}/`,
      ),
    );
  }

  sendReaction(messageId: string, emoji: string): Promise<ChatMessage> {
    return lastValueFrom(
      this.http.patch<ChatMessage>(
        `${environment.apiUrl}api/chat/messages/${messageId}/reactions/`,
        {
          emoji,
        },
      ),
    );
  }

  sendViewed(messageId: string): Promise<ChatMessage> {
    return lastValueFrom(
      this.http.patch<ChatMessage>(
        `${environment.apiUrl}api/chat/messages/${messageId}/viewed/`,
        {},
      ),
    );
  }
}
