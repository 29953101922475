import { inject, Injectable } from '@angular/core';
import { Store } from '@fc-core/store-utils/store';
import { UpdateState } from '@fc-core/store-utils/update-state';
import { ChatMessage } from '../models/chat-message';
import { TokenData } from '@fc-core/services';
import { ChannelPayload, ChatApiService } from '../models/channel.payload';
import { Channel } from '../models/channel';

export interface ChatState {
  messages: { [channel: string]: Map<string, ChatMessage> };
  channels: Channel[];
  messagesLoading: boolean;
  online: string[];
}

const initialState: ChatState = {
  messages: {},
  channels: [],
  messagesLoading: false,
  online: [],
};

@Injectable({ providedIn: 'root' })
export class ChatStore extends Store<ChatState> {
  api = inject(ChatApiService);
  tokenData: TokenData = JSON.parse(
    atob(localStorage.getItem('token').split('.')[1]),
  );

  constructor() {
    super(initialState);
  }

  async getChannels(): Promise<Channel[]> {
    const channels = await this.api.getChannels();
    this.update({ channels });
    return channels;
  }

  async createChannel(channelPayload: ChannelPayload): Promise<Channel> {
    const channel = await this.api.createChannel(channelPayload);
    this.update({
      messages: {
        ...this._state().messages,
        [channel.id]: new Map(),
      },
      channels: [...this._state().channels, channel],
    });

    return channel;
  }

  @UpdateState
  updateChannel(channel: Channel, draft?: ChatState): void {
    draft.channels[draft.channels.findIndex((c) => c.id === channel.id)] =
      channel;
  }

  @UpdateState
  sendMessage(channel: string, message: ChatMessage, draft?: ChatState): void {
    draft.messages[channel] = draft.messages[channel] ?? new Map();
    draft.messages[channel].set(message.id, message);
  }

  @UpdateState
  upsertMessage(message: ChatMessage, draft?: ChatState): void {
    draft.messages[message.channel] =
      draft.messages[message.channel] ?? new Map();
    draft.messages[message.channel].set(message.id, message);
  }

  @UpdateState
  setMessages(
    messages: ChatMessage[],
    channel: string,
    draft?: ChatState,
  ): void {
    draft.messages[channel] = new Map(
      messages.map((message) => [message.id, message]),
    );
  }

  @UpdateState
  setOnline(online: string[], draft?: ChatState): void {
    draft.online = online;
  }

  @UpdateState
  deleteMessage(messageId: string, channel: string, draft?: ChatState): void {
    draft.messages[channel].delete(messageId);
  }

  @UpdateState
  deleteChannel(channelId: string, draft?: ChatState): void {
    draft.channels = draft.channels.filter(
      (channel) => channel.id !== channelId,
    );
    draft.messages = Object.fromEntries(
      Object.entries(draft.messages).filter(([key]) => key !== channelId),
    );
  }
}
